import React, { useState } from "react";
import AlertCard from "../AlertCard";

function AlertCardBankruptcy() {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const alertNews008 = (
    <li className="alertCard eight">
      <div className="cardText">
        <h3>
          Syndyk Getin Noble Banku przedstawia program ugód dla kredytobiorców
          GNB
        </h3>
        <br></br>
        <p className="lead">
          <span className="bold">
            Zgodnie z&nbsp;wcześniejszymi zapowiedziami syndyk masy upadłości
            Getin Noble Bank&nbsp;S.A. Marcin Kubiczek przedstawił propozycje
            ugód dla pierwszej grupy klientów upadłego banku, którzy wzięli
            kredyt na cele mieszkaniowe. Warunki programu ugód musi jeszcze
            zatwierdzić sędzia-komisarz nadzorujący upadłość Getin Noble Banku.
          </span>
        </p>

        {!isExpanded && (
          <a
            className="readMore"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleToggle();
            }}
          >
            Czytaj więcej
          </a>
        )}
        {isExpanded && (
          <>
            <br></br>
            <p className="bold">Kryteria programu</p>
            <p>
              <span className="italic">
                - Odkąd zostałem wyznaczony do funkcji syndyka masy upadłości
                Getinu, deklarowałem, że chciałbym jak największą liczbę sporów
                sądowych wytoczonych bankowi zakończyć polubownie.
                Przygotowaliśmy program ugód dla konsumentów, którzy wzięli
                kredyt na cele mieszkaniowe –
              </span>{" "}
              mówi syndyk Getin Noble Bank S.A. w&nbsp;upadłości Marcin
              Kubiczek.{" "}
              <span className="italic">
                – Pierwsze propozycje ugodowe będą skierowane do klientów,
                którzy spłacili co najmniej 200 proc. kapitału kredytu. To jest
                grupa, wobec której program ugód można uruchomić najszybciej.
                Chcielibyśmy, by ostatecznie wszystkie grupy „frankowiczów”
                zostały objęte programem ugód –
              </span>{" "}
              tłumaczy syndyk Getin Noble Banku.
            </p>
            <p>
              Marcin Kubiczek wyjaśnia, że z&nbsp;pierwszego etapu programu ugód
              będą mogli skorzystać klienci z&nbsp;tej grupy, którzy zaakceptują
              zrzeczenie się wzajemnych roszczeń.{" "}
              <span className="italic">
                – W&nbsp;sytuacji zaakceptowania propozycji ugody kredytobiorca
                wycofuje zgłoszenie wierzytelności oraz cofa ewentualne
                powództwo, a&nbsp;bank wystawia mu list mazalny, usuwa jego dane
                z&nbsp;Biura Informacji Kredytowej i&nbsp;również zrzeka się
                wszelkich roszczeń
              </span>{" "}
              – mówi syndyk.
            </p>
            <p>
              Wypracowanie programu ugód dla klientów upadłego Getin Noble Banku
              było efektem konsultacji z&nbsp;różnymi interesariuszami:{" "}
              <span className="italic">
                – W&nbsp;ostatnich miesiącach odbyliśmy szereg konstruktywnych
                rozmów z&nbsp;pełnomocnikami klientów Getinu oraz
                przedstawicielami dużych grup frankowiczów. Dziękuję przede
                wszystkim Pełnomocniczce Ministra Sprawiedliwości do spraw
                Ochrony Praw Konsumenta. Dr&nbsp;Aneta Wiewiórowska-Domagalska
                aktywnie wspierała nas w&nbsp;pracy nad programem ugód. Dzięki
                aktywności resortu rośnie popularność ugód jako sposobu
                rozwiązania sporów „frankowych”. Mam nadzieję, że kredytobiorcy
                GNB również wpiszą się w&nbsp;ten trend
              </span>{" "}
              – podkreśla Marcin Kubiczek. Kredytobiorcy Getin Noble Banku
            </p>
            <p>
              Kredytobiorcy Getin Noble Banku, którzy chcieliby uzyskać więcej
              informacji na temat programu ugód, mogą kierować pytania na
              specjalnie utworzony adres kontaktowy:{" "}
              <a href="mailto:ugody@gnbbank.pl" className="gold">
                ugody@gnbbank.pl
              </a>
              .
            </p>
            <br></br>
            <p className="bold">Szansa na zakończenie sporów sądowych</p>
            <p>
              Zawarcie ugód będzie skutkowało umorzeniem dużej części postępowań
              sądowych wytoczonych bankowi. To oznacza ograniczenie kosztów,
              które wszystkie strony ponoszą w związku z&nbsp;ich prowadzeniem:
              <span className="italic">
                - Przyjęcie ugód jest korzystne nie tylko dla klientów upadłego
                banku oraz dla masy upadłości, ale także dla sądów, które są
                przeciążone tzw. sprawami frankowymi –
              </span>{" "}
              mówi syndyk Marcin Kubiczek.
            </p>
            <br></br>
            <p className="bold">Zapóźnienie technologiczne Getinu</p>
            <p>
              Syndyk tłumaczy, że największym wyzwaniem w&nbsp;pracy nad
              programem ugód była niespójna infrastruktura danowa
              i&nbsp;techniczna.{" "}
              <span className="italic">
                - Praca nad systemem ugód jest niezwykle skomplikowana ze
                względu na tzw. dług technologiczny, którym bank jest obarczony.
                To efekt wieloletnich zaniedbań i&nbsp;niewłaściwych decyzji
                podejmowanych w&nbsp;grupie kapitałowej Leszka Czarneckiego.
                Przestarzałe systemy, na których przez lata funkcjonował Getin
                Noble Bank, nigdy nie zostały zintegrowane
                i&nbsp;zaktualizowane. To generuje szereg problemów związanych
                ze stanem baz danych. Dlatego przeprowadzenie programu ugód jest
                logistycznie i&nbsp;technicznie możliwe dopiero teraz –
              </span>
              tłumaczy Marcin Kubiczek.
            </p>
            <p>
              Syndyk podkreśla, że z&nbsp;powodu technologicznej dezorganizacji
              banku utrudniona była obróbka i&nbsp;weryfikacja danych, na
              podstawie których były tworzone modele prognostyczne pod
              propozycje ugód. W&nbsp;skrajnych przypadkach niezbędna była
              ręczna obróbka poszczególnych przypadków i&nbsp;sięganie do
              dokumentów źródłowych w&nbsp;formie niezdigitalizowanej. Dopiero
              na tej podstawie możliwe było pewne opracowanie danego przypadku.
            </p>
            <p>
              <span className="italic">
                - Przeprowadzenie takiego procesu dla ponad 30 tys. przypadków
                umów kredytowych, bez możliwości wiarygodnej agregacji danych,
                jest dla upadłego Getinu dużym wyzwaniem organizacyjnym
                i&nbsp;finansowym. Gdyby działalność finansowa banku była
                wcześniej prowadzona w&nbsp;sposób właściwy, skala napotkanych
                nieprawidłowości byłaby dziś znacznie mniejsza. Jednak dzięki
                wytężonej pracy naszego zespołu udało się wypracować model,
                który zaspokoi potrzeby wszystkich stron i&nbsp;będzie stanowić
                realną szansę na ustabilizowanie sytuacji kredytobiorców –
              </span>{" "}
              wyjaśnia syndyk Marcin Kubiczek.
            </p>
            <a
              className="readMore"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleToggle();
              }}
            >
              Zwiń
            </a>
          </>
        )}
      </div>
      <p className="cardDate">Opublikowano 11.12.2024&nbsp;r.</p>
    </li>
  );

  return (
    <>
      <AlertCard content={alertNews008} />
    </>
  );
}

export default AlertCardBankruptcy;
