import React from "react";
import "../css/Documents.css";
import Accordion from "../components/Accordion";
import DocumentLink from "../components/DocumentLink";
import { useScrollReveal } from "../hooks/useScrollReveal";

import bondProspectusPp6_001 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp6/01_Prospekt-emisyjny-podstawowy-obligacji-na-okaziciela-Getin-Noble-Bank-SA.pdf";
import bondProspectusPp6_002 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp6/02_Aneks-nr-1-do-prospektu-opublikowany-w-dniu-24-stycznia-2017-r.pdf";
import bondProspectusPp6_003 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp6/03_Aneks-nr-2-do-prospektu-opublikowany-w-dniu-21-marca-2017-r.pdf";
import bondProspectusPp6_004 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp6/04_Aneks-nr-3-do-prospektu-opublikowany-w-dniu-20-kwietnia-2017.pdf";
import bondProspectusPp6_005 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp6/05_Aneks-nr-4-do-prospektu-opublikowany-w-dniu-24-maja-2017-r.pdf";
import bondProspectusPp6_006 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp6/06_Komunikat-aktualizujacy-nr-1.pdf";
import bondProspectusPp6_007 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp6/07_Aneks-nr-5-do-prospektu-opublikowany-w-dniu-18-września-2017-r.pdf";

import bondProspectusPp5_001 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp5/01_Prospekt-emisyjny-podstawowy-obligacji-na-okaziciela-Getin-Noble-Bank-SA.pdf";
import bondProspectusPp5_002 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp5/02_Komunikat-aktualizujacy-nr-2.pdf";
import bondProspectusPp5_003 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp5/03_Komunikat-aktualizujacy-nr-3.pdf";
import bondProspectusPp5_004 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp5/04_Aneks-nr-1-do-prospektu-opublikowany-w-dniu-27-listopada-2015-r.pdf";
import bondProspectusPp5_005 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp5/05_Aneks-nr-2-do-prospektu-opublikowany-w-dniu-8-grudnia-2015-r.pdf";
import bondProspectusPp5_006 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp5/06_Aneks-nr-3-do-prospektu-opublikowany-w-dniu-22-stycznia-2016-r.pdf";
import bondProspectusPp5_007 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp5/07_Aneks-nr-4-do-prospektu-opublikowany-w-dniu-25-lutego-2016-r.pdf";
import bondProspectusPp5_008 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp5/08_Komunikat-aktualizujacy-nr-4-z-dnia-1-marca-2016-r.pdf";
import bondProspectusPp5_009 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp5/09_Aneks-nr-5-do-prospektu-opublikowany-w-dniu-3-marca-2016-r.pdf";
import bondProspectusPp5_010 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp5/10_Komunikat_aktualizujacy_nr-5.pdf";
import bondProspectusPp5_011 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp5/11_Aneks-nr-6-do-prospektu-opublikowany-w-dniu-24-marca-2016-r.pdf";
import bondProspectusPp5_012 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp5/12_Komunikat-aktualizujacy-nr-6.pdf";
import bondProspectusPp5_013 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp5/13_Aneks-nr-7-do-prospektu-opublikowany-w-dniu-25-maja-2016-r.pdf";
import bondProspectusPp5_014 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp5/14_Komunikat-aktualizujacy-nr-7.pdf";

import bondProspectusPp4_001 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp4/01_Prospekt-emisyjny-podstawowy-obligacji-na-okaziciela-Getin-Noble-Bank-SA.pdf";
import bondProspectusPp4_002 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp4/02_Aneks-nr-1-do-prospektu-opublikowany-w-dniu-1-kwietnia-2015-r.pdf";

import bondProspectusPp3_001 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp3/01_Prospekt-emisyjny-podstawowy-obligacji-na-okaziciela-Getin-Noble-Bank-SA.pdf";
import bondProspectusPp3_002 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp3/02_Aneks-nr-1-do-prospektu-opublikowany-w-dniu-17-maja-2013-r.pdf";
import bondProspectusPp3_003 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp3/03_Aneks-nr-2-do-prospektu-opublikowany-w-dniu-12-września-2013-r.pdf";
import bondProspectusPp3_004 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp3/04_Komunikat-aktualizujacy-nr-1.pdf";
import bondProspectusPp3_005 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp3/05_Aneks-nr-3-do-prospektu-opublikowany-w-dniu-22-listopada-2013-r.pdf";
import bondProspectusPp3_006 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp3/06_Aneks-nr-4-do-prospektu-opublikowany-w-dniu-5-marca-2014-r.pdf";

import bondProspectusPp2_001 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp2/01_Prospekt-emisyjny-podstawowy-obligacji-na-okaziciela-Getin-Noble-Bank-SA.pdf";
import bondProspectusPp2_002 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp2/02_Aneks-nr-1-do-prospektu-opublikowany-w-dniu-20-sierpnia-2012-r.pdf";
import bondProspectusPp2_003 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp2/03_Aneks-nr-2-do-prospektu-opublikowany-w-dniu-21-listopada-2012-r.pdf";
import bondProspectusPp2_004 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp2/04_Komunikat-aktualizacyjny-nr-1.pdf";
import bondProspectusPp2_005 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp2/05_Aneks-nr-3-do-prospektu-opublikowany-w-dniu-11-marca-2013-r.pdf";
import bondProspectusPp2_006 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp2/06_Komunikat-aktualizacyjny-nr-2.pdf";

import bondProspectusPp1_001 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp1/01_Prospekt-emisyjny-podstawowy-obligacji-na-okaziciela-Getin-Noble-Bank-SA.pdf";
import bondProspectusPp1_002 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp1/02_Komunikat-aktualizujacy-nr-1.pdf";
import bondProspectusPp1_003 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp1/03_Aneks-nr-1-do-prospektu-opublikowany-w-dniu-9-marca-2012-r.pdf";
import bondProspectusPp1_004 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp1/04_Aneks-nr-2-do-prospektu-opublikowany-w-dniu-18-maja-2012-r.pdf";
import bondProspectusPp1_005 from "../doc/historyData/BondProspectus/prospekt-emisyjny_pp1/05_Komunikat-w-sprawie-zmiany-adresu-Emitenta.pdf";

import finalTermsPp6_001 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp6/01_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP6-I-z-dnia-13-pazdziernika-2016.pdf";
import finalTermsPp6_002 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp6/02_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP6-II-z-dnia-4-listopada-2016.pdf";
import finalTermsPp6_003 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp6/03_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP6-III-z-dnia-1-grudnia-2016.pdf";
import finalTermsPp6_004 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp6/04_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP6-IV-z-dnia-30-grudnia-2016.pdf";
import finalTermsPp6_005 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp6/05_Nota-Zmieniająca-do-Warunków-Emisji-Obligacji-Serii-PP6-IV.pdf";
import finalTermsPp6_006 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp6/06_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP6-IV-po-zmienie-z-dnia-17-stycznia-2017.pdf";
import finalTermsPp6_007 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp6/07_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP6-V-z-dnia-28-lutego-2017.pdf";
import finalTermsPp6_008 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp6/08_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP6-VI-z-dnia-3-kwietnia-2017.pdf";
import finalTermsPp6_009 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp6/09_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP6-VI-po-zmianie-z-dnia-18-kwietnia-2017.pdf";
import finalTermsPp6_010 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp6/10_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP6-VII-z-dnia-2-maja-2017.pdf";
import finalTermsPp6_011 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp6/11_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP6-VIII-z-dnia-1-czerwca-2017.pdf";
import finalTermsPp6_012 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp6/12_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP6-IX-z-dnia-30-czerwca-2017.pdf";
import finalTermsPp6_013 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp6/13_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP6-X-z-dnia-31-lipca-2017.pdf";
import finalTermsPp6_014 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp6/14_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP6-XI-z-dnia-20-wrzesnia-2017.pdf";

import finalTermsPp5_001 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp5/01_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP5-I-z-dnia-20-listopada-2015.pdf";
import finalTermsPp5_002 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp5/02_Komunikat-aktualizujacy-nr-3.pdf";
import finalTermsPp5_003 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp5/03_Nota-Zmieniajaca-do-Warunkow-Emisji-Obligacji-Serii-PP5-II.pdf";
import finalTermsPp5_004 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp5/04_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP5-II-tekst-jednolity-uwzgl-zmiane-z-dnia-10-marca-2016-ogloszona-14-marca-2016.pdf";
import finalTermsPp5_005 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp5/05_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP5-III-z-dnia-5-kwietnia-2016.pdf";
import finalTermsPp5_006 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp5/06_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP5-IV-z-dnia-29-kwietnia-2016.pdf";
import finalTermsPp5_007 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp5/07_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP5-V-tekst-jednolity-uwzgl-zmiane-z-dnia-18-lipca-2016-ogłoszona-20-lipca-2016.pdf";
import finalTermsPp5_008 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp5/08_Nota-Zmieniajaca-do-Warunkow-Emisji-Obligacji-Serii-PP5-V.pdf";
import finalTermsPp5_009 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp5/09_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP5-VI-z-dnia-2-sierpnia-2016.pdf";

import finalTermsPp3_001 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp3/01_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP3-I-z-dnia-16-maja-2013.pdf";
import finalTermsPp3_002 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp3/02_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP3-II-z-dnia-10-czerwca-2013.pdf";
import finalTermsPp3_003 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp3/03_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP3-III-z-dnia-31-lipca-2013.pdf";
import finalTermsPp3_004 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp3/04_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP3-IV-z-dnia-22-sierpnia-2013.pdf";
import finalTermsPp3_005 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp3/05_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP3-V-z-dnia-16-wrzesnia-2013.pdf";
import finalTermsPp3_006 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp3/06_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP3-VI-z-dnia-14-pazdziernika-2013.pdf";
import finalTermsPp3_007 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp3/07_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP3-VII-z-dnia-4-listopada-2013.pdf";
import finalTermsPp3_008 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp3/08_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP3-VIII-z-dnia-25-listopada-2013.pdf";
import finalTermsPp3_009 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp3/09_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP3-IX-z-dnia-20-stycznia-2014.pdf";
import finalTermsPp3_010 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp3/10_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP3-X-z-dnia-10-lutego-2014.pdf";
import finalTermsPp3_011 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp3/11_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP3-XI-z-dnia-7-marca-2014.pdf";

import finalTermsPp2_001 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp2/01_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP2-I-z-dnia-20-lipca-2012.pdf";
import finalTermsPp2_002 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp2/02_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP2-II-z-dnia-14-wrzesnia-2012.pdf";
import finalTermsPp2_003 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp2/03_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP2-III-z-dnia-28-wrzesnia-2012.pdf";
import finalTermsPp2_004 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp2/04_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP2-IV-z-dnia-5-listopada.pdf";
import finalTermsPp2_005 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp2/05_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP2-V-z-dnia-3-grudnia-2012.pdf";
import finalTermsPp2_006 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp2/06_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP2-VI-z-dnia-21-grudnia-2012.pdf";
import finalTermsPp2_007 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp2/07_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP2-VII-z-dnia-30-stycznia-2013.pdf";
import finalTermsPp2_008 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp2/08_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP2-VIII-z-dnia-4-marca-2013.pdf";
import finalTermsPp2_009 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp2/09_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP2-IX-z-dnia-8-kwietnia-2013.pdf";

import finalTermsPp1_001 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp1/01_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP-I-z-dnia-25-stycznia-2012.pdf";
import finalTermsPp1_002 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp1/02_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP-II-z-dnia-15-lutego-2012.pdf";
import finalTermsPp1_003 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp1/03_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP-III-z-dnia-2-marca-2012.pdf";
import finalTermsPp1_004 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp1/04_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP-IV-z-dnia-6-kwietnia-2012.pdf";
import finalTermsPp1_005 from "../doc/historyData/FinalTerms/ostateczne-warunki-emisji_pp1/05_Ostateczne-Warunki-Oferty-Obligacji-Serii-PP-V-z-dnia-7-maja-2012.pdf";

import reportsAndDisclosuresPp6_001 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/01_Informacja-o-wyniku-Oferty-Obligacji-PP6-I.pdf";
import reportsAndDisclosuresPp6_002 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/02_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP6-I-do-kapitalu-Tier-II.pdf";
import reportsAndDisclosuresPp6_003 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/03_Informacja-o-wyniku-Oferty-Obligacji-PP6-II.pdf";
import reportsAndDisclosuresPp6_004 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/04_Informacja-o-rejestracji-Obligacji-serii-PP6-I.pdf";
import reportsAndDisclosuresPp6_005 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/05_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP6-II-do-kapilału-Tier II.pdf";
import reportsAndDisclosuresPp6_006 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/06_Informacja-o-wyniku-Oferty-Obligacji-PP6-III.pdf";
import reportsAndDisclosuresPp6_007 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/07_Informacja-o-rejestracji-Obligacji-serii-PP6-II.pdf";
import reportsAndDisclosuresPp6_008 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/08_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP6-III-do-kapitalu-Tier-II.pdf";
import reportsAndDisclosuresPp6_009 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/09_Informacja-o-wyniku-Oferty-Obligacji-PP6-IV.pdf";
import reportsAndDisclosuresPp6_010 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/10_Informacja-o-rejestracji-Obligacji-serii-PP6-III.pdf";
import reportsAndDisclosuresPp6_011 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/11_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP6-IV-do-kapitalu-Tier-II.pdf";
import reportsAndDisclosuresPp6_012 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/12_Informacja-o-rejestracji-Obligacji-serii-PP6-IV.pdf";
import reportsAndDisclosuresPp6_013 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/13_Informacja-o-wyniku-Oferty-Obligacji-PP6-V.pdf";
import reportsAndDisclosuresPp6_014 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/14_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP6-V-do-kapitalu-Tier-II.pdf";
import reportsAndDisclosuresPp6_015 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/15_Informacja-o-wyniku-Oferty-Obligacji-PP6-VI.pdf";
// import reportsAndDisclosuresPp6_016 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/16_Informacja-o-rejestracji-Obligacji-serii-PP6-V.pdf";
import reportsAndDisclosuresPp6_017 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/17_Informacja-o-rejestracji-Obligacji-serii-PP6-VI.pdf";
import reportsAndDisclosuresPp6_018 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/18_Informacja-o-wyniku-Oferty-Obligacji-PP6-VII.pdf";
import reportsAndDisclosuresPp6_019 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/19_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP6-VI-do-kapitalu-Tier-II.pdf";
import reportsAndDisclosuresPp6_020 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/20_Informacja-o-wyniku-Oferty-Obligacji-PP6-VIII.pdf";
import reportsAndDisclosuresPp6_021 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/21_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP6-VII-do-kapitalu-Tier-II.pdf";
import reportsAndDisclosuresPp6_022 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/22_Informacja-o-rejestracji-Obligacji-serii-PP6-VII.pdf";
import reportsAndDisclosuresPp6_023 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/23_Informacja-o-wyniku-Oferty-Obligacji-PP6-IX.pdf";
import reportsAndDisclosuresPp6_024 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/24_Informacja-o-rejestracji-Obligacji-serii-PP6-VIII.pdf";
import reportsAndDisclosuresPp6_025 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/25_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP6-VIII-do-kapitalu-Tier-II.pdf";
import reportsAndDisclosuresPp6_026 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/26_Informacja-o-rejestracji-Obligacji-serii-PP6-IX.pdf";
import reportsAndDisclosuresPp6_027 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/27_Informacja-o-wyniku-Oferty-Obligacji-PP6-X.pdf";
import reportsAndDisclosuresPp6_028 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/28_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP6-IX-do-kapitalu-Tier-II.pdf";
import reportsAndDisclosuresPp6_029 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/29_Informacja-o-rejestracji-Obligacji-serii-PP6-X.pdf";
import reportsAndDisclosuresPp6_030 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/30_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP6-X-do-kapitalu-Tier-II.pdf";
import reportsAndDisclosuresPp6_031 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/31_Informacja-o-wyniku-Oferty-Obligacji-PP6-XI.pdf";
import reportsAndDisclosuresPp6_032 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/32_Informacja-o-rejestracji-Obligacji-serii-PP6-XI.pdf";
import reportsAndDisclosuresPp6_033 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp6/33_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP6-XI-do-kapitalu-Tier-II.pdf";

import reportsAndDisclosuresPp5_001 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/01_Informacja-o-wyniku-Oferty-Obligacji-PP5-I.pdf";
import reportsAndDisclosuresPp5_002 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/02_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP5-I-do-funduszy uzupelniajacych.pdf";
import reportsAndDisclosuresPp5_003 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/03_Informacja-o-rejestracji-Obligacji-serii-PP5-I.pdf";
import reportsAndDisclosuresPp5_004 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/04_Informacja-o-wyniku-Oferty-Obligacji-PP5-II.pdf";
import reportsAndDisclosuresPp5_005 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/05_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP5-II-do-kapitalu-Tier-II.pdf";
import reportsAndDisclosuresPp5_006 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/06_Informacja-o-wyniku-Oferty-Obligacji-PP5-III.pdf";
import reportsAndDisclosuresPp5_007 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/07_Informacja-o-rejestracji-Obligacji-serii-PP5-II.pdf";
import reportsAndDisclosuresPp5_008 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/08_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP5-III-do-kapitalu-Tier-II.pdf";
import reportsAndDisclosuresPp5_009 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/09_Informacja-o-wyniku-Oferty-Obligacji-PP5-IV.pdf";
import reportsAndDisclosuresPp5_010 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/10_Informacja-o-rejestracji-Obligacji-serii-PP5-III.pdf";
import reportsAndDisclosuresPp5_011 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/11_Informacja-o-rejestracji-Obligacji-serii-PP5-IV.pdf";
import reportsAndDisclosuresPp5_012 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/12_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP5-IV-do-kapitalu-Tier-II.pdf";
import reportsAndDisclosuresPp5_013 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/13_Informacja-o-wyniku-Oferty-Obligacji-PP5-V.pdf";
import reportsAndDisclosuresPp5_014 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/14_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP5-V-do-kapitalu-Tier-II.pdf";
import reportsAndDisclosuresPp5_015 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/15_Informacja-o-rejestracji-Obligacji-serii-PP5-V.pdf";
import reportsAndDisclosuresPp5_016 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/16_Informacja-o-wyniku-Oferty-Obligacji-PP5-VI.pdf";
import reportsAndDisclosuresPp5_017 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/17_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP5-VI-do-kapitalu-Tier-II.pdf";
import reportsAndDisclosuresPp5_018 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp5/18_Informacja-o-rejestracji-Obligacji-serii-PP5-VI.pdf";

import reportsAndDisclosuresPp3_001 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/01_Informacja-o-wyniku-Oferty-Obligacji-PP3-I.pdf";
import reportsAndDisclosuresPp3_002 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/02_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP3-I-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp3_003 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/03_Informacja-o-rejestracji-Obligacji-serii-PP3-I.pdf";
import reportsAndDisclosuresPp3_004 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/04_Informacja-o-wyniku-Oferty-Obligacji-PP3-II.pdf";
import reportsAndDisclosuresPp3_005 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/05_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP3-II-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp3_006 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/06_Informacja-o-rejestracji-Obligacji-serii-PP3-II.pdf";
import reportsAndDisclosuresPp3_007 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/07_Informacja-o-wyniku-Oferty-Obligacji-PP3-III.pdf";
import reportsAndDisclosuresPp3_008 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/08_Informacja-o-wyniku-Oferty-Obligacji-PP3-IV.pdf";
import reportsAndDisclosuresPp3_009 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/09_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP3-III-oraz-serii-PP3-IV-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp3_010 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/10_Komunikat-w-sprawie-zmiany-adresu-Emitenta-z-dnia-2-pazdziernika-2013r.pdf";
import reportsAndDisclosuresPp3_011 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/11_Informacja-o-rejestracji-Obligacji-serii-PP3-III-oraz-serii-PP3-IV.pdf";
import reportsAndDisclosuresPp3_012 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/12_Informacja-o-wyniku-Oferty-Obligacji-PP3-V.pdf";
import reportsAndDisclosuresPp3_013 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/13_Informacja-o-rejestracji-Obligacji-serii-PP3-V.pdf";
import reportsAndDisclosuresPp3_014 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/14_Informacja-o-wyniku-Oferty-Obligacji-PP3-VI.pdf";
import reportsAndDisclosuresPp3_015 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/15_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP3-V-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp3_016 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/16_Informacja-o-wyniku-Oferty-Obligacji-PP3-VII.pdf";
import reportsAndDisclosuresPp3_017 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/17_Informacja-o-rejestracji-Obligacji-serii-PP3-VI.pdf";
import reportsAndDisclosuresPp3_018 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/18_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP3-VI-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp3_019 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/19_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP3-VII-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp3_020 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/20_Informacja-o-wyniku-Oferty-Obligacji-PP3-VIII.pdf";
import reportsAndDisclosuresPp3_021 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/21_Informacja-o-rejestracji-Obligacji-serii-PP3-VII.pdf";
import reportsAndDisclosuresPp3_022 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/22_Informacja-o-rejestracji-Obligacji-serii-PP3-VIII.pdf";
import reportsAndDisclosuresPp3_023 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/23_Informacja-o-wyniku-Oferty-Obligacji-PP3-IX.pdf";
import reportsAndDisclosuresPp3_024 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/24_Informacja-o-rejestracji-Obligacji-serii-PP3-IX.pdf";
import reportsAndDisclosuresPp3_025 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/25_Informacja-o-wyniku-Oferty-Obligacji-PP3-X.pdf";
import reportsAndDisclosuresPp3_026 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/26_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP3-VIII-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp3_027 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/27_Informacja-o-rejestracji-Obligacji-serii-PP3-X.pdf";
import reportsAndDisclosuresPp3_028 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/28_Informacja-o-wyniku-Oferty-Obligacji-PP3-XI.pdf";
import reportsAndDisclosuresPp3_029 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/29_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP3-IX-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp3_030 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/30_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP3-X-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp3_031 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/31_Informacja-o-rejestracji-Obligacji-serii-PP3-XI.pdf";
import reportsAndDisclosuresPp3_032 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp3/32_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP3-XI-do-funduszy-uzupelniajacych.pdf";

import reportsAndDisclosuresPp2_001 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/01_Informacja-o-wyniku-Oferty-Obligacji-PP2-I.pdf";
import reportsAndDisclosuresPp2_002 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/02_Informacja-o-rejestracji-obligacji-serii-PP2-I-w-KDPW.pdf";
import reportsAndDisclosuresPp2_003 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/03_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP2-I-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp2_004 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/04_Informacja-o-wyniku-oferty-Obligacji-PP2-II.pdf";
import reportsAndDisclosuresPp2_005 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/05_Informacja-o-rejestracji-obligacji-serii-PP2-II-w-KDPW.pdf";
import reportsAndDisclosuresPp2_006 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/06_Informacja-o-rejestracji-srodkow-z-Obligacji-serii-PP2-II-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp2_007 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/07_Informacja-o-wyniku-oferty-Obligacji-PP2-III.pdf";
import reportsAndDisclosuresPp2_008 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/08_Informacje-o-rejestracji-obligacji-serii-PP2-III-w-KDPW.pdf";
import reportsAndDisclosuresPp2_009 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/09_Informacje-o-zaliczeniu-srodkow-z-Obligacji-serii-PP2-III-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp2_010 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/10_Informacje-o-wyniku-oferty-Obligacji-PP2-IV.pdf";
import reportsAndDisclosuresPp2_011 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/11_Informacja-o-rejestracji-obligacji-serii-PP2-IV-w-KDPW.pdf";
import reportsAndDisclosuresPp2_012 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/12_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP2-IV-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp2_013 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/13_Informacja-o-wyniku-oferty-Obligacji-PP2-V.pdf";
import reportsAndDisclosuresPp2_014 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/14_Informacja-o-rejestracji-serii-PP2-V-w-KDPW.pdf";
import reportsAndDisclosuresPp2_015 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/15_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP2-V-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp2_016 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/16_Informacja-o-wyniku-oferty-Obligacji-PP2-VI.pdf";
import reportsAndDisclosuresPp2_017 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/17_Informacja-o-wyniku-oferty-Obligacji-PP2-VII.pdf";
import reportsAndDisclosuresPp2_018 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/18_Informacja-o-rejestracji-serii-PP2-VII-w-KDPW.pdf";
import reportsAndDisclosuresPp2_019 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/19_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP2-VII-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp2_020 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/20_Informacja-o-wyniku-Oferty-Obligacji-PP2-VIII.pdf";
import reportsAndDisclosuresPp2_021 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/21_Informacja-o-rejestracji-serii-PP2-VIII-w-KDPW.pdf";
import reportsAndDisclosuresPp2_022 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/22_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP2-VIII-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp2_023 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/23_Informacja-o-wyniku-Oferty-Obligacji-serii-PP2-IX.pdf";
import reportsAndDisclosuresPp2_024 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/24_Informacja-o-rejestracji-obligacji-PP2-IX-w-KDPW.pdf";
import reportsAndDisclosuresPp2_025 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/25_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP2-IX-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp2_026 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp2/26_Komunikat-w-sprawie-zmiany-adresu-Emitenta-z-dnia-2-pazdziernika-2013r.pdf";

import reportsAndDisclosuresPp1_001 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/01_Raport-biezacy-nr-12-2012-Zatwierdzenie-prospektu-emisyjnego-obligacji-Getin-Noble-Bank-SA.pdf";
import reportsAndDisclosuresPp1_002 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/02_Komunikat-zatwierdzenie-prospektu-emisyjnego-obligacji-Getin-Noble-Bank-SA.pdf";
import reportsAndDisclosuresPp1_003 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/03_Komunikat-Informacja-dotyczaca-zlozenia-prospektu-obligacji-w-KNF-oraz-terminu-publikacji-prospektu-i-ostatecznych-warunkow-emisji-obligacji.pdf";
import reportsAndDisclosuresPp1_004 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/04_Informacja-o-wyniku-Oferty-Obligacji-PP-I.pdf";
import reportsAndDisclosuresPp1_005 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/05_Informacja-o-wyniku-Oferty-Obligacji-PP-II.pdf";
import reportsAndDisclosuresPp1_006 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/06_Informacja-o-rejestracji-Obligacji-serii-PP-I-oraz-serii-PP-II-w-KDPW.pdf";
import reportsAndDisclosuresPp1_007 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/07_Informacja-o-zaliczeniu-srodkow-z-Obligacji-serii-PP-I-oraz-serii-PP-II-do-funduszy-uzupelniajacyh.pdf";
import reportsAndDisclosuresPp1_008 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/08_Informacja-o-wyniku-Oferty-Obligacji-PP-III.pdf";
import reportsAndDisclosuresPp1_009 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/09_Informacja-o-rejestracji-obligacji-serii-PP-III.pdf";
import reportsAndDisclosuresPp1_010 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/10_Informacja-o-zaliczeniu-srodkow-z-Obligacji-Serii-PP-III-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp1_011 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/11_Informacja-o-wyniku-Oferty-Obligacji-PP-IV.pdf";
import reportsAndDisclosuresPp1_012 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/12_Informacja-o-rejestracji-obligacji-serii PP-IV.pdf";
import reportsAndDisclosuresPp1_013 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/13_Informacja-o-zaliczeniu-srodkow-z-Obligacji-Serii-PP-IV-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp1_014 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/14_Informacja-o-wyniku-Oferty-Obligacji-PP-V.pdf";
import reportsAndDisclosuresPp1_015 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/15_Informacja-o-rejestracji-obligacji-serii-PP-V.pdf";
import reportsAndDisclosuresPp1_016 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/16_Informacja-o-zaliczeniu-srodkow-z-Obligacji-Serii-PP-V-do-funduszy-uzupelniajacych.pdf";
import reportsAndDisclosuresPp1_017 from "../doc/historyData/ReportsAndDisclosures/raporty-i-informacje_pp1/17_Komunikat-w-sprawie-zmiany-adresu-Emitenta-z-dnia-2-pazdziernika-2013r.pdf";

function Documents() {
  const bondProspectusPp6 = [
    <>
      <DocumentLink
        href={bondProspectusPp6_001}
        linkText="Prospekt emisyjny podstawowy obligacji na okaziciela Getin Noble Bank S.A."
      />
      <DocumentLink
        href={bondProspectusPp6_002}
        linkText="Aneks nr 1 do prospektu opublikowany w dniu 24 stycznia 2017 r."
      />
      <DocumentLink
        href={bondProspectusPp6_003}
        linkText="Aneks nr 2 do prospektu opublikowany w dniu 21 marca 2017 r."
      />
      <DocumentLink
        href={bondProspectusPp6_004}
        linkText="Aneks nr 3 do prospektu opublikowany w dniu 20 kwietnia 2017"
      />
      <DocumentLink
        href={bondProspectusPp6_005}
        linkText="Aneks nr 4 do prospektu opublikowany w dniu 24 maja 2017 r."
      />
      <DocumentLink
        href={bondProspectusPp6_006}
        linkText="Komunikat aktualizujący nr 1"
      />
      <DocumentLink
        href={bondProspectusPp6_007}
        linkText="Aneks nr 5 do prospektu opublikowany w dniu 18 września 2017 r."
      />
    </>,
  ];

  const bondProspectusPp5 = [
    <>
      <DocumentLink
        href={bondProspectusPp5_001}
        linkText="Prospekt emisyjny podstawowy obligacji na okaziciela Getin Noble Bank S.A."
      />
      <DocumentLink
        href={bondProspectusPp5_002}
        linkText="Komunikat aktualizujący nr 2"
      />
      <DocumentLink
        href={bondProspectusPp5_003}
        linkText="Komunikat aktualizujący nr 3"
      />
      <DocumentLink
        href={bondProspectusPp5_004}
        linkText="Aneks nr 1 do prospektu opublikowany w dniu 27 listopada 2015 r."
      />
      <DocumentLink
        href={bondProspectusPp5_005}
        linkText="Aneks nr 2 do prospektu opublikowany w dniu 8 grudnia 2015 r."
      />
      <DocumentLink
        href={bondProspectusPp5_006}
        linkText="Aneks nr 3 do prospektu opublikowany w dniu 22 stycznia 2016 r."
      />
      <DocumentLink
        href={bondProspectusPp5_007}
        linkText="Aneks nr 4 do prospektu opublikowany w dniu 25 lutego 2016 r."
      />
      <DocumentLink
        href={bondProspectusPp5_008}
        linkText="Komunikat aktualizujący nr 4 z dnia 1 marca 2016 r."
      />
      <DocumentLink
        href={bondProspectusPp5_009}
        linkText="Aneks nr 5 do prospektu opublikowany w dniu 3 marca 2016 r."
      />
      <DocumentLink
        href={bondProspectusPp5_010}
        linkText="Komunikat aktualizujący nr 5"
      />
      <DocumentLink
        href={bondProspectusPp5_011}
        linkText="Aneks nr 6 do prospektu opublikowany w dniu 24 marca 2016 r."
      />
      <DocumentLink
        href={bondProspectusPp5_012}
        linkText="Komunikat aktualizujący nr 6"
      />
      <DocumentLink
        href={bondProspectusPp5_013}
        linkText="Aneks nr 7 do prospektu opublikowany w dniu 25 maja 2016 r."
      />
      <DocumentLink
        href={bondProspectusPp5_014}
        linkText="Komunikat aktualizujący nr 7"
      />
    </>,
  ];

  const bondProspectusPp4 = [
    <>
      <DocumentLink
        href={bondProspectusPp4_001}
        linkText="Prospekt emisyjny podstawowy obligacji na okaziciela Getin Noble Bank S.A."
      />
      <DocumentLink
        href={bondProspectusPp4_002}
        linkText="Aneks nr 1 do prospektu opublikowany w dniu 1 kwietnia 2015 r."
      />
    </>,
  ];

  const bondProspectusPp3 = [
    <>
      <DocumentLink
        href={bondProspectusPp3_001}
        linkText="Prospekt emisyjny podstawowy obligacji na okaziciela Getin Noble Bank S.A."
      />
      <DocumentLink
        href={bondProspectusPp3_002}
        linkText="Aneks nr 1 do prospektu opublikowany w dniu 17 maja 2013 r."
      />
      <DocumentLink
        href={bondProspectusPp3_003}
        linkText="Aneks nr 2 do prospektu opublikowany w dniu 12 września 2013 r."
      />
      <DocumentLink
        href={bondProspectusPp3_004}
        linkText="Komunikat aktualizujący nr 1"
      />
      <DocumentLink
        href={bondProspectusPp3_005}
        linkText="Aneks nr 3 do prospektu opublikowany w dniu 22 listopada 2013 r."
      />
      <DocumentLink
        href={bondProspectusPp3_006}
        linkText="Aneks nr 4 do prospektu opublikowany w dniu 5 marca 2014 r."
      />
    </>,
  ];

  const bondProspectusPp2 = [
    <>
      <DocumentLink
        href={bondProspectusPp2_001}
        linkText="Prospekt emisyjny podstawowy obligacji na okaziciela Getin Noble Bank S.A."
      />
      <DocumentLink
        href={bondProspectusPp2_002}
        linkText="Aneks nr 1 do prospektu opublikowany w dniu 20 sierpnia 2012 r."
      />
      <DocumentLink
        href={bondProspectusPp2_003}
        linkText="Aneks nr 2 do prospektu opublikowany w dniu 21 listopada 2012 r."
      />
      <DocumentLink
        href={bondProspectusPp2_004}
        linkText="Komunikat aktualizacyjny nr 1"
      />
      <DocumentLink
        href={bondProspectusPp2_005}
        linkText="Aneks nr 3 do prospektu opublikowany w dniu 11 marca 2013 r."
      />
      <DocumentLink
        href={bondProspectusPp2_006}
        linkText="Komunikat aktualizacyjny nr 2"
      />
    </>,
  ];

  const bondProspectusPp1 = [
    <>
      <DocumentLink
        href={bondProspectusPp1_001}
        linkText="Prospekt emisyjny podstawowy obligacji na okaziciela Getin Noble Bank S.A."
      />
      <DocumentLink
        href={bondProspectusPp1_002}
        linkText="Komunikat aktualizujący nr 1"
      />
      <DocumentLink
        href={bondProspectusPp1_003}
        linkText="Aneks nr 1 do prospektu opublikowany w dniu 9 marca 2012 r."
      />
      <DocumentLink
        href={bondProspectusPp1_004}
        linkText="Aneks nr 2 do prospektu opublikowany w dniu 18 maja 2012 r."
      />
      <DocumentLink
        href={bondProspectusPp1_005}
        linkText="Komunikat w sprawie zmiany adresu Emitenta"
      />
    </>,
  ];

  const finalTermsPp6 = [
    <>
      <DocumentLink
        href={finalTermsPp6_001}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP6-I z dnia 13 października 2016"
      />
      <DocumentLink
        href={finalTermsPp6_002}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP6-II z dnia 4 listopada 2016"
      />
      <DocumentLink
        href={finalTermsPp6_003}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP6-III z dnia 1 grudnia 2016"
      />
      <DocumentLink
        href={finalTermsPp6_004}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP6-IV z dnia 30 grudnia 2016"
      />
      <DocumentLink
        href={finalTermsPp6_005}
        linkText="Nota Zmieniająca do Warunków Emisji Obligacji Serii PP6-IV"
      />
      <DocumentLink
        href={finalTermsPp6_006}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP6-IV po zmienie z dnia 17 stycznia 2017"
      />
      <DocumentLink
        href={finalTermsPp6_007}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP6-V z dnia 28 lutego 2017"
      />
      <DocumentLink
        href={finalTermsPp6_008}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP6-VI z dnia 3 kwietnia 2017"
      />
      <DocumentLink
        href={finalTermsPp6_009}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP6-VI po zmianie z dnia 18 kwietnia 2017"
      />
      <DocumentLink
        href={finalTermsPp6_010}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP6-VII z dnia 2 maja 2017"
      />
      <DocumentLink
        href={finalTermsPp6_011}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP6-VIII z dnia 1 czerwca 2017"
      />
      <DocumentLink
        href={finalTermsPp6_012}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP6-IX z dnia 30 czerwca 2017"
      />
      <DocumentLink
        href={finalTermsPp6_013}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP6-X z dnia 31 lipca 2017"
      />
      <DocumentLink
        href={finalTermsPp6_014}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP6-XI z dnia 20 września 2017"
      />
    </>,
  ];

  const finalTermsPp5 = [
    <>
      <DocumentLink
        href={finalTermsPp5_001}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP5-I z dnia 20 listopada 2015"
      />
      <DocumentLink
        href={finalTermsPp5_002}
        linkText="Komunikat aktualizujący nr 3"
      />
      <DocumentLink
        href={finalTermsPp5_003}
        linkText="Nota Zmieniająca do Warunków Emisji Obligacji Serii PP5-II"
      />
      <DocumentLink
        href={finalTermsPp5_004}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP5-II - tekst jednolity uwzgl. zmianę z dnia 10 marca 2016 ogłoszoną 14 marca 2016"
      />
      <DocumentLink
        href={finalTermsPp5_005}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP5-III z dnia 5 kwietnia 2016"
      />
      <DocumentLink
        href={finalTermsPp5_006}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP5-IV z dnia 29 kwietnia 2016"
      />
      <DocumentLink
        href={finalTermsPp5_007}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP5-V - tekst jednolity uwzgl. zmianę z dnia 18 lipca 2016 ogłoszoną 20 lipca 2016"
      />
      <DocumentLink
        href={finalTermsPp5_008}
        linkText="Nota Zmieniająca do Warunków Emisji Obligacji Serii PP5-V"
      />
      <DocumentLink
        href={finalTermsPp5_009}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP5-VI z dnia 2 sierpnia 2016"
      />
    </>,
  ];

  const finalTermsPp3 = [
    <>
      <DocumentLink
        href={finalTermsPp3_001}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP3-I z dnia 16 maja 2013"
      />
      <DocumentLink
        href={finalTermsPp3_002}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP3-II z dnia 10 czerwca 2013"
      />
      <DocumentLink
        href={finalTermsPp3_003}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP3-III z dnia 31 lipca 2013"
      />
      <DocumentLink
        href={finalTermsPp3_004}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP3-IV z dnia 22 sierpnia 2013"
      />
      <DocumentLink
        href={finalTermsPp3_005}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP3-V z dnia 16 września 2013"
      />
      <DocumentLink
        href={finalTermsPp3_006}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP3-VI z dnia 14 października 2013"
      />
      <DocumentLink
        href={finalTermsPp3_007}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP3-VII z dnia 4 listopada 2013"
      />
      <DocumentLink
        href={finalTermsPp3_008}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP3-VIII z dnia 25 listopada 2013"
      />
      <DocumentLink
        href={finalTermsPp3_009}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP3-IX z dnia 20 stycznia 2014"
      />
      <DocumentLink
        href={finalTermsPp3_010}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP3-X z dnia 10 lutego 2014"
      />
      <DocumentLink
        href={finalTermsPp3_011}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP3-XI z dnia 7 marca 2014"
      />
    </>,
  ];

  const finalTermsPp2 = [
    <>
      <DocumentLink
        href={finalTermsPp2_001}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP2-I z dnia 20 lipca 2012"
      />
      <DocumentLink
        href={finalTermsPp2_002}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP2-II z dnia 14  września 2012"
      />
      <DocumentLink
        href={finalTermsPp2_003}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP2-III z dnia 28 września 2012"
      />
      <DocumentLink
        href={finalTermsPp2_004}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP2-IV z dnia 5 listopada"
      />
      <DocumentLink
        href={finalTermsPp2_005}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP2-V z dnia 3 grudnia 2012"
      />
      <DocumentLink
        href={finalTermsPp2_006}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP2-VI z dnia 21 grudnia 2012"
      />
      <DocumentLink
        href={finalTermsPp2_007}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP2-VII z dnia 30 stycznia 2013"
      />
      <DocumentLink
        href={finalTermsPp2_008}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP2-VIII z dnia 4 marca 2013"
      />
      <DocumentLink
        href={finalTermsPp2_009}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP2-IX z dnia 8 kwietnia 2013"
      />
    </>,
  ];

  const finalTermsPp1 = [
    <>
      <DocumentLink
        href={finalTermsPp1_001}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP-I z dnia 25 stycznia 2012"
      />
      <DocumentLink
        href={finalTermsPp1_002}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP-II z dnia 15 lutego 2012"
      />
      <DocumentLink
        href={finalTermsPp1_003}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP-III z dnia 2 marca 2012"
      />
      <DocumentLink
        href={finalTermsPp1_004}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP-IV z dnia 6 kwietnia 2012"
      />
      <DocumentLink
        href={finalTermsPp1_005}
        linkText="Ostateczne Warunki Oferty Obligacji Serii PP-V z dnia 7 maja 2012"
      />
    </>,
  ];

  const reportsAndDisclosuresPp6 = [
    <>
      <DocumentLink
        href={reportsAndDisclosuresPp6_001}
        linkText="Informacja o wyniku Oferty Obligacji PP6-I"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_002}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP6-I do kapitału Tier II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_003}
        linkText="Informacja o wyniku Oferty Obligacji PP6-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_004}
        linkText="Informacja o rejestracji Obligacji serii PP6-I"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_005}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP6-II do kapitału Tier II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_006}
        linkText="Informacja o wyniku Oferty Obligacji PP6-III"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_007}
        linkText="Informacja o rejestracji Obligacji serii PP6-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_008}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP6-III do kapitału Tier-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_009}
        linkText="Informacja o wyniku Oferty Obligacji PP6-IV"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_010}
        linkText="Informacja o rejestracji Obligacji serii PP6-III"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_011}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP6-IV do kapitału Tier-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_012}
        linkText="Informacja o rejestracji Obligacji serii PP6-IV"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_013}
        linkText="Informacja o wyniku Oferty Obligacji PP6-V"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_014}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP6-V do kapitału Tier-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_015}
        linkText="Informacja o wyniku Oferty Obligacji PP6-VI"
      />
      {/* <DocumentLink
        href={reportsAndDisclosuresPp6_016}
        linkText="Informacja o rejestracji Obligacji serii PP6-V"
      /> */}
      <DocumentLink
        href={reportsAndDisclosuresPp6_017}
        linkText="Informacja o rejestracji Obligacji serii PP6-VI"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_018}
        linkText="Informacja o wyniku Oferty Obligacji PP6-VII"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_019}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP6-VI do kapitału Tier-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_020}
        linkText="Informacja o wyniku Oferty Obligacji PP6-VIII"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_021}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP6-VII do kapitału Tier-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_022}
        linkText="Informacja o rejestracji Obligacji serii PP6-VII"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_023}
        linkText="Informacja o wyniku Oferty Obligacji PP6-IX"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_024}
        linkText="Informacja o rejestracji Obligacji serii PP6-VIII"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_025}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP6-VIII do kapitału Tier-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_026}
        linkText="Informacja o rejestracji Obligacji serii PP6-IX"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_027}
        linkText="Informacja o wyniku Oferty Obligacji PP6-X"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_028}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP6-IX do kapitału Tier-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_029}
        linkText="Informacja o rejestracji Obligacji serii PP6-X"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_030}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP6-X do kapitału Tier-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_031}
        linkText="Informacja o wyniku Oferty Obligacji PP6-XI"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_032}
        linkText="Informacja o rejestracji Obligacji serii PP6-XI"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp6_033}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP6-XI do kapitału Tier-II"
      />
    </>,
  ];

  const reportsAndDisclosuresPp5 = [
    <>
      <DocumentLink
        href={reportsAndDisclosuresPp5_001}
        linkText="Informacja o wyniku Oferty Obligacji PP5-I"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_002}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP5-I do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_003}
        linkText="Informacja o rejestracji Obligacji serii PP5-I"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_004}
        linkText="Informacja o wyniku Oferty Obligacji PP5-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_005}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP5-II do kapitału Tier II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_006}
        linkText="Informacja o wyniku Oferty Obligacji PP5-III"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_007}
        linkText="Informacja o rejestracji Obligacji serii PP5-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_008}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP5-III do kapitału Tier II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_009}
        linkText="Informacja o wyniku Oferty Obligacji PP5-IV"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_010}
        linkText="Informacja o rejestracji Obligacji serii PP5-III"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_011}
        linkText="Informacja o rejestracji Obligacji serii PP5-IV"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_012}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP5-IV do kapitału Tier II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_013}
        linkText="Informacja o wyniku Oferty Obligacji PP5-V"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_014}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP5-V do kapitału Tier II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_015}
        linkText="Informacja o rejestracji Obligacji serii PP5-V"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_016}
        linkText="Informacja o wyniku Oferty Obligacji PP5-VI"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_017}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP5-VI do kapitału Tier II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp5_018}
        linkText="Informacja o rejestracji Obligacji serii PP5-VI"
      />
    </>,
  ];

  const reportsAndDisclosuresPp3 = [
    <>
      <DocumentLink
        href={reportsAndDisclosuresPp3_001}
        linkText="Informacja o wyniku Oferty Obligacji PP3-I"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_002}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP3-I do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_003}
        linkText="Informacja o rejestracji Obligacji serii PP3-I"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_004}
        linkText="Informacja o wyniku Oferty Obligacji PP3-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_005}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP3-II do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_006}
        linkText="Informacja o rejestracji Obligacji serii PP3-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_007}
        linkText="Informacja o wyniku Oferty Obligacji PP3-III"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_008}
        linkText="Informacja o wyniku Oferty Obligacji PP3-IV"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_009}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP3-III oraz serii PP3-IV do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_010}
        linkText="Komunikat w sprawie zmiany adresu Emitenta z dnia 2 października 2013r."
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_011}
        linkText="Informacja o rejestracji Obligacji serii PP3-III oraz serii PP3-IV"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_012}
        linkText="Informacja o wyniku Oferty Obligacji PP3-V"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_013}
        linkText="Informacja o rejestracji Obligacji serii PP3-V"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_014}
        linkText="Informacja o wyniku Oferty Obligacji PP3-VI"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_015}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP3-V do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_016}
        linkText="Informacja o wyniku Oferty Obligacji PP3-VII"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_017}
        linkText="Informacja o rejestracji Obligacji serii PP3-VI"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_018}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP3-VI do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_019}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP3-VII do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_020}
        linkText="Informacja o wyniku Oferty Obligacji PP3-VIII"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_021}
        linkText="Informacja o rejestracji Obligacji serii PP3-VII"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_022}
        linkText="Informacja o rejestracji Obligacji serii PP3-VIII"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_023}
        linkText="Informacja o wyniku Oferty Obligacji PP3-IX"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_024}
        linkText="Informacja o rejestracji Obligacji serii PP3-IX"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_025}
        linkText="Informacja o wyniku Oferty Obligacji PP3-X"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_026}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP3-VIII do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_027}
        linkText="Informacja o rejestracji Obligacji serii PP3-X"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_028}
        linkText="Informacja o wyniku Oferty Obligacji PP3-XI"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_029}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP3-IX do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_030}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP3-X do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_031}
        linkText="Informacja o rejestracji Obligacji serii PP3-XI"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp3_032}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP3-XI do funduszy uzupełniających"
      />
    </>,
  ];

  const reportsAndDisclosuresPp2 = [
    <>
      <DocumentLink
        href={reportsAndDisclosuresPp2_001}
        linkText="Informacja o wyniku Oferty Obligacji PP2-I"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_002}
        linkText="Informacja o rejestracji obligacji serii PP2-I w KDPW"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_003}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP2-I do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_004}
        linkText="Informacja o wyniku oferty Obligacji PP2-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_005}
        linkText="Informacja o rejestracji obligacji serii PP2-II w KDPW"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_006}
        linkText="Informacja o rejestracji środków z Obligacji serii PP2-II do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_007}
        linkText="Informacja o wyniku oferty Obligacji PP2-III"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_008}
        linkText="Informacje o rejestracji obligacji serii PP2-III w KDPW"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_009}
        linkText="Informacje o zaliczeniu środków z Obligacji serii PP2-III do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_010}
        linkText="Informacje o wyniku oferty Obligacji PP2-IV"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_011}
        linkText="Informacja o rejestracji obligacji serii PP2-IV w KDPW"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_012}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP2-IV do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_013}
        linkText="Informacja o wyniku oferty Obligacji PP2-V"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_014}
        linkText="Informacja o rejestracji serii PP2-V w KDPW"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_015}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP2-V do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_016}
        linkText="Informacja o wyniku oferty Obligacji PP2-VI"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_017}
        linkText="Informacja o wyniku oferty Obligacji PP2-VII"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_018}
        linkText="Informacja o rejestracji serii PP2-VII w KDPW"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_019}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP2-VII do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_020}
        linkText="Informacja o wyniku Oferty Obligacji PP2-VIII"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_021}
        linkText="Informacja o rejestracji serii PP2-VIII w KDPW"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_022}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP2-VIII do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_023}
        linkText="Informacja o wyniku Oferty Obligacji serii PP2-IX"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_024}
        linkText="Informacja o rejestracji obligacji PP2-IX w KDPW"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_025}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP2-IX do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp2_026}
        linkText="Komunikat w sprawie zmiany adresu Emitenta z dnia 2 października 2013r."
      />
    </>,
  ];

  const reportsAndDisclosuresPp1 = [
    <>
      <DocumentLink
        href={reportsAndDisclosuresPp1_001}
        linkText="Raport bieżący nr 12/2012 - Zatwierdzenie prospektu emisyjnego obligacji Getin Noble Bank S.A."
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_002}
        linkText="Komunikat - zatwierdzenie prospektu emisyjnego obligacji Getin Noble Bank S.A."
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_003}
        linkText="Komunikat - Informacja dotycząca złożenia prospektu obligacji w KNF oraz terminu publikacji prospektu i ostatecznych warunków emisji obligacji"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_004}
        linkText="Informacja o wyniku Oferty Obligacji PP-I"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_005}
        linkText="Informacja o wyniku Oferty Obligacji PP-II"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_006}
        linkText="Informacja o rejestracji Obligacji serii PP-I oraz serii PP-II w KDPW"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_007}
        linkText="Informacja o zaliczeniu środków z Obligacji serii PP-I oraz serii PP-II do funduszy uzupełniającyh"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_008}
        linkText="Informacja o wyniku Oferty Obligacji PP-III"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_009}
        linkText="Informacja o rejestracji obligacji serii PP-III"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_010}
        linkText="Informacja o zaliczeniu środków z Obligacji Serii PP-III do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_011}
        linkText="Informacja o wyniku Oferty Obligacji PP-IV"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_012}
        linkText="Informacja o rejestracji obligacji serii PP-IV"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_013}
        linkText="Informacja o zaliczeniu środków z Obligacji Serii PP-IV do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_014}
        linkText="Informacja o wyniku Oferty Obligacji PP-V"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_015}
        linkText="Informacja o rejestracji obligacji serii PP-V"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_016}
        linkText="Informacja o zaliczeniu środków z Obligacji Serii PP-V do funduszy uzupełniających"
      />
      <DocumentLink
        href={reportsAndDisclosuresPp1_017}
        linkText="Komunikat w sprawie zmiany adresu Emitenta z dnia 2 października 2013r."
      />
    </>,
  ];

  useScrollReveal({ selector: "#historyData" });

  useScrollReveal({
    selector: ".container",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main>
      <section id="documents">
        <div className="container">
          <header>
            <h1 className="heading">
              Dane historyczne Getin Noble Bank S.A. w&nbsp;upadłości
            </h1>
          </header>{" "}
          {/* <div className="searchBar">
            <input
              type="text"
              placeholder="Wyszukaj dokument..."
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  setSearchTerm(event.target.value);
                  console.log(`Wyszukano: ${event.target.value}`);
                }
              }}
            />
          </div> */}
          <ul className="accordionBox">
            <Accordion
              title="Prospekt emisyjny"
              content={
                <div>
                  <Accordion title="PP6" content={bondProspectusPp6} />
                  <Accordion title="PP5" content={bondProspectusPp5} />
                  <Accordion title="PP4" content={bondProspectusPp4} />
                  <Accordion title="PP3" content={bondProspectusPp3} />
                  <Accordion title="PP2" content={bondProspectusPp2} />
                  <Accordion title="PP1" content={bondProspectusPp1} />
                </div>
              }
            />
            <Accordion
              title="Ostateczne warunki emisji"
              content={
                <div>
                  <Accordion title="PP6" content={finalTermsPp6} />
                  <Accordion title="PP5" content={finalTermsPp5} />
                  <Accordion title="PP3" content={finalTermsPp3} />
                  <Accordion title="PP2" content={finalTermsPp2} />
                  <Accordion title="PP1" content={finalTermsPp1} />
                </div>
              }
            />
            <Accordion
              title="Raporty i informacje"
              content={
                <div>
                  <Accordion title="PP6" content={reportsAndDisclosuresPp6} />
                  <Accordion title="PP5" content={reportsAndDisclosuresPp5} />
                  <Accordion title="PP3" content={reportsAndDisclosuresPp3} />
                  <Accordion title="PP2" content={reportsAndDisclosuresPp2} />
                  <Accordion title="PP1" content={reportsAndDisclosuresPp1} />
                </div>
              }
            />
          </ul>
        </div>
      </section>
    </main>
  );
}

export default Documents;
